.create-locations-body {
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 77vh;
}

.create-locations-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-location-error-message {
  color: red;
}

.city-name {
  display: flex;
  margin-bottom: 1rem;
}

.city-code {
  display: flex;
  margin-bottom: 1rem;
}

.save-cancel-btns button {
  margin: 0.5rem;
  cursor: pointer;
}

.required-p {
  color: red;
  font-weight: bold;
}

.my-td-input.hideSelect.functions {
  display: none;
}

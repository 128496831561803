.my-button {
  padding: 0.2rem 1rem;
  margin: 0.5rem;
  color: white;
  font-size: 1.4rem;
  border: solid;
  border-width: 0.1rem;
  border-radius: 0.3rem;
  border-color: yellow;
  background-color: rgb(148, 148, 148);
  cursor: pointer;
}

.my-button.disabled {
  opacity: 0.5;
  cursor: default;
}

@media screen and (max-width: 1000px) {
  .my-button {
    padding: 0.2rem 1rem;
    margin: 0.5rem;
    color: white;
    font-size: 1rem;
    border: solid;
    border-width: 0.1rem;
    border-radius: 0.3rem;
    border-color: yellow;
    background-color: rgb(148, 148, 148);
    margin-top: -7px !important;
  }
}

.create-function-body {
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 77vh;
}

.create-function-label {
  padding-left: 5.5rem;
  padding-right: 5.5rem;
  border: 3px solid #aaaaaa;
}

.create-function-label img {
  width: 3rem;
  height: 3rem;
}

.my-td-input textArea {
  width: 96%;
  border-radius: 10px;
  border: none;
}

textarea {
  resize: none;
}

/* .trach-function-img{ //TODO: remove these commented classes
    padding-top: 12px;
    cursor: pointer;
}

.trach-function-img:hover{
    opacity: 0.5;
} */

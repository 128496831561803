/* body.active-modal {
  overflow-y: hidden;
} */
/* to hide the scrollbar on Firefox */
*{
scrollbar-width: none;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content-unit {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 80%;
  background: #f1f1f1;
  padding: 2rem 2rem;
  border-radius: 3px;
  /* max-width: 1000px; */
  min-width: 950px;
  min-height: 150px;
  z-index: 2;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.trash {
  display: flex;
  cursor: pointer;
}

.modal-content-unit h1 {
  padding: 1rem;
  text-align: center;
}

.modal-content-unit input {
  width: 20rem;
  margin-right: 1rem;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.modal-content h2 {
  margin-bottom: 2rem;
}

.modal-content button {
  margin: 2rem;
}

.popup-btns-count {
  display: flex;
  justify-content: flex-end !important;
}

/* to hide the scrollbar on Chrome */
.modal-content-unit::-webkit-scrollbar {
  display: none; 
}

@media screen and (max-width: 1800px) {
  .modal-content-unit {
    top: 50%;
    left: 50%;
    /* max-width: 531px; */
    min-width: 600px;
    min-height: 150px;
  }
}

@media screen and (max-width: 1000px) {
  .popup-btns {
    margin-top: 20px;
  }
}

.large-textbox-number input {
    width: 47rem !important;
}

.number-description {
    width: 47rem !important;
    border-radius:10px;
    border:none;
    resize: none;
}

.reset-btn-number {
    display: flex;
    flex-direction: revert;
    position: absolute;
    top: 23rem;
    right: 2rem;
}

.header-number h2 {
    margin-top: -3.5rem;
}

.evaluation-number {
    background-color: rgba(233, 228, 228, 0.8);
    padding: 0.5rem 1.5rem;
    /* display: flex; */
    margin: 0 0.2rem;
}

@media screen and (min-width: 1120px) and (max-width:1300px) {
    .reset-btn-number {
        top: 22.5rem;
    }
}

@media screen and (min-width: 1050px) and (max-width:1100px) {
    .reset-btn-number {
        top: 21.5rem;
    }
}

@media screen and (max-width: 1050px) {
    .reset-btn-number {
        top: 21rem;
    }
    .large-textbox-number input {
        width: 35rem !important;
    }
    .number-description {
        width: 35rem !important;
    }
    .function-table-number {
        margin-top: 2rem;
    }
}

@media screen and (min-width: 900px) and (max-width:1050px) {
    .reset-btn-number {
        top: 23rem;
    }
    .function-table-number {
        margin-top: 2rem;
    }
}
.edit-users-body {
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 77vh;
}

.edit-users-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-td input {
  width: 95%;
}

.change-password input {
  width: 96%;
}

.my-td-label-user {
  border-width: 3px;
  border-color: #aaaaaa;
  border-style: solid;
  padding: 0.7rem;
  padding-right: 1rem;
}

select {
  border: 0;
  height: 3rem;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .change-password input {
    width: 93%;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: 10px; */
}

.my-td-label-locations {
  border-width: 3px;
  border-color: #aaaaaa;
  border-style: solid;
  padding: 0.7rem;
}

.my-td {
  /* border-width: 3px;
  border-color: #aaaaaa;
  border-style: solid; */
  padding: 5px;
}

@media screen and (max-width: 1350px) {
  .locations {
    display: flex;
    overflow-x: scroll;
    overflow: auto;
  }
  .locations .my-td{
    font-size: 15px;
  }
}

@media screen and (max-width: 900px) {
  .locations .my-td{
    padding:0 10px;
  }
}
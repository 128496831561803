.user-final-page-body {
  min-height: 58vh;
}

.user-final-page-body ol {
  padding: 0 0.5rem;
}

.final-page-done-button {
  padding: 0.4rem 3rem;
  margin: 0.5rem;
  color: rgba(0, 0, 0, 0.809);
  font-size: 1.3rem;
  border: solid;
  border-width: 0.1rem;
  border-radius: 0.3rem;
  border-color: rgb(39, 186, 39);
  background-color: rgb(39, 186, 39);
}

.final-page-done-button-note {
  background-color: rgb(174, 174, 174);
  width: 13rem;
  text-align: center;
}

.final-page-back-next-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 0 1.8rem;
  align-items: flex-end;
}

.final-page-back-next-buttons button {
  width: 11rem;
}

.final-page-li {
  display: flex;
  margin: 0.3rem 0;
  padding: 0;
  align-items: center;
}

.final-page-li div h4 {
  width: 12rem;
}

.final-page-img {
  width: 7rem;
  height: 4rem;
  /* add border and background color for the image */
  border-radius: 10px;
  background-color: white;
}

.final-page-edit-icon {
  width: 1.3rem;
  height: 1.3rem;
  margin-left: 1rem;
}

.final-page-values-edit-icon {
  display: flex;
  align-items: center;
}

.final-page-values-edit-icon img {
  margin-left: 0.5rem;
}

.user-final-page-item {
  display: flex;
  margin: 0.5rem;
  align-items: center;
}

.user-final-page-image-container {
  display: flex;
  align-items: center;
  /* add this property */
  justify-content: center;
}

.final-page-job-parameter-values {
  font-weight: 600;
  /* width: 95%; */
}

.final-page-check-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}

.table-count-user {
  padding: 0.3rem;
  width: 6rem;
  max-width: 10rem;
  flex-wrap: wrap;
}

.count-table-final-page {
  border: 1px solid black;
  border-collapse: collapse;
}

.user-count-body {
  min-height: 53vh;
  margin: 0.6rem;
}

.user-count-table {
  width: 100%;
}

.Beschreibung {
  width: 35% !important;
  padding: 2px;
  background-color: rgb(174, 174, 174);
  margin-right: 0.5rem;
}
.Transporteinheit {
  width: 25%;
  padding: 2px;
  background-color: rgb(174, 174, 174);
  margin-right: 0.5rem;
}
.Anzahl {
  background-color: rgb(174, 174, 174);
  padding: 2px;
  width: 15%;
}

.user-count-count input {
  width: 95%;
}

.user-count-icons {
  width: 0%;
}

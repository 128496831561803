.date-body {
  min-height: 53vh;
  margin: 2rem;
  /* text-align: center; */
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 14.77rem;
}

/* .react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker-popper {
  position: relative;
  inset: 0px 0 auto 0px;
  transform: translate3d(32px, 218px, 0px);
}

.datepicker-control-section {
  max-width: 246px;
  margin: 30px auto;
  padding-top: 50px;
} */

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: rgb(196, 196, 30) !important;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 3px !important;
  font-size: 16px !important;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "X";
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: rgb(196, 196, 30) !important;

  color: #fff;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: yellow;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: yellow;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: yellow;
}

.e-input-group:not(.e-disabled):not(.e-float-icon-left)::before,
.e-input-group:not(.e-disabled):not(.e-float-icon-left)::after,
.e-input-group.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::before,
.e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled)
  .e-input-in-wrap::after {
  background: yellow;
}

.e-input.e-error,
.e-input.e-error:focus,
.e-input-group.e-error input.e-input,
.e-input-group.e-control-wrapper.e-error input.e-input,
.e-input-group.e-control-wrapper.e-error .e-input-group-icon,
.e-input-group.e-error .e-input-group-icon,
.e-input-group.e-error textarea.e-input,
.e-input-group.e-control-wrapper.e-error textarea.e-input {
  border-color: yellow;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: yellow;
}
.e-input-group.e-error,
.e-input-group.e-control-wrapper.e-error,
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border-bottom-color: yellow;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-color: transparent;
  border-color: transparent;
  color: yellow;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: yellow;
}

/* To remove the line in the input */
.e-calendar-blue.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::before,
.e-calendar-blue.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::after,
.e-calendar-blue.e-input-group.e-control-wrapper.e-date-wrapper::before,
.e-calendar-blue.e-input-group.e-control-wrapper.e-date-wrapper::after,
.e-calendar-blue.e-input-group:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-calendar-blue.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-calendar-blue.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-calendar-blue.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
  background: none;
  border: none;
  transition: none;
}
/* To change the selected day's and today's color*/

.e-calendar-blue .e-calendar .e-content td.e-selected span.e-day,
.e-calendar-blue .e-calendar .e-content td.e-today.e-selected span.e-day {
  background: yellow;
  color: white;
}
.e-calendar-blue .e-calendar .e-content td.e-today span.e-day {
  color: yellow;
  border: none;
  box-shadow: none;
}
/* To change the Today button and date icon color*/

.e-calendar-blue.e-date-wrapper
  span.e-input-group-icon.e-date-icon.e-icons.e-active,
.e-calendar-blue .e-btn.e-flat.e-primary,
.e-calendar-blue .e-css.e-btn.e-flat.e-primary {
  color: yellow;
}
.e-calendar-blue .e-btn.e-flat.e-primary::hover {
  background: rgb(19 112 219 / 17%);
  color: yellow;
}

.e-datepicker {
  padding-left: 10px !important;
}

/* body.active-modal {
  overflow-y: hidden;
} */

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content-process-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  padding: 14px 28px;
  width: 28%;
  max-width: 600px;
  min-width: 300px;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  border: solid 3px yellow;
  background: #f1f1f1;
}

.modal-content-process-popup > h2 {
  text-align: center;
}

.process-popup-name-field-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.process-popup-name-field-container > h3 {
  margin-right: 1rem;
}

.process-popup-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.process-popup-image-container > .icon-trash-popup {
  margin-top: 8rem;
  margin-left: 0.5rem;
  opacity: 0.5;
  cursor: pointer;
}

.process-popup-image {
  width: 15rem;
  margin-top: 1rem;
  /* max-width: 15rem; */
  max-height: 35vh;
}

.process-popup-form-control-input {
  border-radius: 0;
}

.process-popup-btns {
  display: flex;
  justify-content: space-between;
}

.process-popup-btns > .my-button {
  padding: 0.2rem 1rem;
  /* margin: 0.5rem; */
  color: white;
  font-size: 1.4rem;
  border: solid;
  border-width: 0.1rem;
  border-radius: 0.3rem;
  border-color: yellow;
  background-color: rgb(148, 148, 148);
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    width: 600px;
    max-width: 600px;
    min-width: 300px;
    z-index: 2;
  }
  .popup-btns {
    display: flex;
    justify-content: center !important;
    margin-top: 22px;
  }
}

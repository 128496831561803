.large-textbox-photo input {
    width: 47rem !important;
}

.photo-description {
    width: 47rem !important;
    border-radius:10px;
    border:none;
    resize: none;
}

.header-photo h2 {
    margin-top: -3.5rem;
}

.evaluation-photo {
    background-color: rgba(233, 228, 228, 0.8);
    padding: 0.5rem 1.5rem;
    /* display: flex; */
    margin: 0 0.2rem;
}

.reset-btn-photo {
    display: flex;
    flex-direction: revert;
    position: absolute;
    top: 23rem;
    right: 2rem;
}

@media screen and (min-width: 1120px) and (max-width:1300px) {
    .reset-btn-photo {
        top: 22.5rem;
    }
}

@media screen and (min-width: 1050px) and (max-width:1100px) {
    .reset-btn-photo {
        top: 21.5rem;
    }
}

@media screen and (max-width: 1050px) {
    .reset-btn-photo {
        top: 21rem;
    }
    .large-textbox-photo input {
        width: 35rem !important;
    }
    .photo-description {
        width: 35rem !important;
    }
    .function-table-photo {
        margin-top: 2rem;
    }
}

@media screen and (min-width: 900px) and (max-width:1050px) {
    .reset-btn-photo {
        top: 23rem;
    }
    .function-table-photo {
        margin-top: 2rem;
    }
}
            .large-textbox-signature input {
                width: 47rem !important;
            }

            .signature-description {
                width: 47rem !important;
                border-radius:10px;
                border:none;
                resize: none;
            }

            .header-signature h2 {
                margin-top: -3.5rem;
            }

            .evaluation-signature {
                background-color: rgba(233, 228, 228, 0.8);
                padding: 0.5rem 1.5rem;
                /* display: flex; */
                margin: 0 0.2rem;
            }

            .reset-btn-signature {
                display: flex;
                flex-direction: revert;
                position: absolute;
                top: 23rem;
                right: 2rem;
            }

            @media screen and (min-width: 1050px) and (max-width:1300px) {
                .reset-btn-signature {
                    top: 24rem;
                }
                .function-table-signature {
                    margin-top: 2rem;
                }
            }

            @media screen and (max-width: 1050px) {
                .reset-btn-signature {
                    top: 21rem;
                }
                .large-textbox-signature input {
                    width: 35rem !important;
                }
                .signature-description {
                    width: 35rem !important;
                }
                .function-table-signature {
                    margin-top: 2rem;
                }
            }

            @media screen and (min-width: 900px) and (max-width:1050px) {
                .reset-btn-signature {
                    top: 23rem;
                }
            }
.manage-functions-body {
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 77vh;
}

@media screen and (max-width: 900px) {
  .manage-functions-body .title-btns {
    font-size: 0.8rem;
  }
}

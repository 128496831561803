.tool-card {
  background-color: rgb(223, 223, 223);
  padding: 0.6rem;
  width: 9rem;
  height: 9em;
  border: solid;
  border-width: 0.1rem;
  border-radius: 1rem;
  border-color: red;
  text-align: center;
}

.tool-card img {
  width: 6rem;
  height: 6rem;
  text-align: center;
  color: black;
  opacity: 1;
}

.user {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.user Label {
  margin-right: 4rem;
  padding: 0.6rem 3rem;
}

.user Button {
  margin-right: 1rem;
}

.userName {
  width: 30rem;
  text-align: center;
}

.my-td-label-user {
  border-width: 3px;
  border-color: #aaaaaa;
  border-style: solid;
  padding: 0.7rem;
  padding-right: 1rem;
}

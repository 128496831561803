.header {
  height: 2.8rem;
  background-color: yellow;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header1 {
  justify-content: flex-start;
  align-items: center;
}

.header2 {
  justify-content: center;
  align-items: center;
  margin-left: 3rem;
}

.header3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header3 p {
  font-size: 20px;
  color: black;
}

.header-btn {
  margin: 0.5rem;
  padding: 0.1rem 1rem;
  background: transparent;
  border: solid;
  border-width: 2px;
  border-radius: 0.3rem;
  border-color: rgb(163, 163, 163);
  font-size: large;
  text-decoration: none;
  font-weight: 600;
}

.header-hr {
  margin: 0.5rem 0;
  background-color: red;
  height: 0.4rem;
}

p {
  background: transparent;
  margin: 0.3rem;
  padding: 0.1rem 0.3rem;
}

.change-password-button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  font-size: 20px;
}

.user-button {
  padding: 0.4rem 1.2rem;
  margin: 0.5rem;
  color: black;
  font-size: 1.4rem;
  border: solid;
  border-width: 0.1rem;
  border-radius: 0.3rem;
  border-color: yellow;
  background-color: yellow;
  cursor: pointer;
}

.disabled {
  background-color: gray;
  opacity: 0.5;
  cursor: default;
}

/* @media screen and (max-width: 500px) {
  .user-button {
    padding: 0.4rem 1.2rem;
    margin: 0.5rem;
    color: black;
    font-size: 1.4rem;
    border: solid;
    border-width: 0.1rem;
    border-radius: 0.3rem;
    border-color: yellow;
    background-color: yellow;
    cursor: pointer;
  }
  .disabled {
    background-color: gray;
    opacity: 0.5;
    cursor: default;
  }
} */

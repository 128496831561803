.assignedFunction {
    display: flex;
    width: 4rem;
    height: 4rem;
    border: solid yellow;
    border-width: 0.1rem;
    border-radius: 10px;
    background-color: white;
    align-items: center;
    justify-content: center;
}

.assignedFunction img {
    width: 3rem;
    height: 3rem;
}

@media screen and (max-width:1335px) {
    .assignedFunction {
        display: flex;
        width: 3.5rem;
        height: 3.5rem;
        border: solid yellow;
        border-width: 0.1rem;
        border-radius: 10px;
        background-color: white;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width:900px) {
    .assignedFunction {
        display: flex;
        width: 3.5rem;
        height: 3.5rem;
        border: solid yellow;
        border-width: 0.1rem;
        border-radius: 10px;
        background-color: white;
        align-items: center;
        justify-content: center;
    }
}
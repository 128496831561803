.navbar {
    padding: 0 0.2rem;
}

.my-ul {
    display: flex;
    padding: 0;
}

.my-li {
    width: 18%;
    text-align: center;
    margin: 0 0.5rem;
    margin-left: 0;
    padding: 0.5rem;
    background-color: rgb(192, 192, 192);
    list-style-type: none;
    border-radius: 0.8rem;
    border-end-start-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.my-li-active {
    width: 18%;
    text-align: center;
    margin: 0 0.5rem;
    margin-left: 0;
    padding: 0.5rem;
    background-color: rgb(192, 192, 192);
    list-style-type: none;
    border-radius: 0.8rem;
    border-end-start-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0px;
}

a {
    color: rgb(66, 63, 63);
    text-decoration: none;
}

.my-is-active {
    font-size: 1.5rem;
    font-weight: 700;
}

@media screen and (max-width: 1300px) {
    .my-li {
        margin: 0 0.3rem;
    }
    .my-li-active {
        margin: 0 0.3rem;
        font-size: 1.2rem;
    }
    .my-is-active {
        font-size: 1.2rem;
    }
}

@media screen and (max-width:900px) {
    .my-li {
        font-size: 0.7rem !important;
    }
    .my-li-active {
        font-size: 0.8rem !important;
    }
}
.sub-processes-user-overview {
  padding: 0 0.5rem;
}

/* adjust the css part and remove the unusable css properties */
.sub-process-overview {
  margin:1rem;
  display: flex;
  flex-wrap: wrap;
  min-height: 70vh;
}

.user-one-sub-process {
  width: 280px;
  height: 225px;
  padding: 7px 2px;
  margin: 0.5rem 0.8rem;
  border-radius: 10px;
  text-align: center;
  background-color: gray;
}

.user-one-sub-process h4 {
  text-align: center;
  margin: 0 2rem;
  width: 96.5%;
  font-weight: bold;
  background-color: #dfdfdf;
  border-radius: 5px;
  margin: 0 5px;
  padding: 3px 0;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.user-sub-process-img {
  border-radius: 5px;
  margin: 0 5px;
  margin-top: 7px;
  width: 290px;
  width: 96%;
  height: 167px;
}

/* Add the media queries for the responsive design */

@media screen and (max-width: 974px) {
  .sub-process-overview  {
    max-width: 640px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1104px) and (max-width: 1400px) {
  .sub-process-overview  {
    max-width: 900px;
    justify-content: center;
  }
}

@media screen and (min-width: 974px) and (max-width: 1103px) {
  .sub-process-overview  {
    max-width: 640px;
    justify-content: center;
  }
}

@media screen and (min-width: 900px) and (max-width: 974px) {
  .sub-process-overview  {
    max-width: 640px;
    justify-content: center;
  }
}

@media screen and (min-width: 840px) and (max-width: 900px) {
  .sub-process-overview  {
    max-width: 640px;
    justify-content: center;
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 840px) {
  .sub-process-overview  {
    max-width: 640px;
    justify-content: center;
  }
}
.signatureContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

/* .signature-user-body { */
  /* min-height: 60vh; */
/* } */

.sigCanvas {
  background-color: white;
  margin: auto;
  width: 85%;
  height: 250px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sub-process-function-name {
  display: flex;
  background-color: rgb(174, 174, 174);
  align-items: center;
  /* justify-content: center; */
}

.sub-process-function-name h3 {
  justify-content: center;
  align-items: center;
  margin-left: 8rem;
}

.back-next-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 0rem 1.8rem;
}

.signPad-clear-button{
     margin-bottom: 1rem;
    color: black;
    font-size: 1.4rem;
    border: solid;
    border-width: 0.1rem;
    border-radius: 0.3rem;
    border-color: yellow;
    background-color: yellow;
    cursor: pointer;
}

.back-next-buttons button {
  width: 10rem;
}


.edit-process-body {
  /* width: 100%; */
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 85vh;
}

.edit-processes {
  display: flex;
  flex-wrap: wrap;
}

.col-1 {
  flex: 2;
  margin: 0 1% 0 0;
}

/* to increase process name 'td' width */

.process-name-dropdown {
  width: 100%;
}

.col-2 {
  flex: 2;
  margin: 0 1%;
}

.col-3 {
  flex: 1;
  margin-left: 0 1%;
}

/* Process and subProcees error message */
.msg-color {
  color: red;
}

.selected-process-msg {
  margin-left: 7rem;
}

.selected-subprocess-msg {
  margin-left: 9.5rem;
}

/* clear input border and give the input 100% of 'td' width */

.col-1 select {
  width: 100%;
}

.col-2-1 select {
  width: 100%;
}

/* .col-3 button {
  margin-bottom: -1rem;
} */

.col-3-2 button {
  margin-top: 1rem;
  padding: 0.3rem 2.55rem;
}

.check-table {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -2.5rem;
}

.check-table td {
  padding: 0 2rem;
}

.check-table td Input {
  width: 4rem;
  height: 4rem;
  padding: 0;
  border: solid yellow;
  border-width: 0.1rem;
}

.hr-dashed {
  margin: 4rem 8rem 0 8rem;
  background-color: transparent;
  height: 0;
  border-top: 0.15rem dashed rgb(126, 126, 126);
}

.toolbox {
  background-color: white;
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 10px;
}

.toolbox-table {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}

.toolbox-table tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10rem;
  justify-content: center;
}

.toolbox-table td {
  padding: 0.7rem 1rem;
}

.select {
  width: 100%;
}

@media screen and (min-width: 880px) and (max-width: 1300px) {
  .check-table td {
    padding: 0 0.5rem;
  }
  .check-table td Input {
    width: 3.5rem;
    height: 3.5rem;
    padding: 0;
    border: solid yellow;
    border-width: 0.1rem;
  }
}

@media screen and (min-width: 948px) and (max-width: 965px) {
  .toolbox-table tr {
    margin: 0 7rem !important;
  }
}

@media screen and (max-width: 882px) {
  .edit-process-body {
    font-size: 0.7rem !important;
  }
  .toolbox-table .tool-card {
    height: 8rem !important;
  }
  .toolbox-table tr {
    margin: 0 3rem;
    justify-content: center;
    width: 597px;
    margin-left: 55px !important;
  }
  .check-table td {
    padding: 0 0.3rem;
  }
  .check-table td Input {
    width: 3.5rem;
    height: 3.5rem;
  }
}

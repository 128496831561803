footer {
  text-align: center;
  font-weight: 500;
  font-size: large;
  background-color: rgb(122, 122, 122);
  position: relative;
  bottom: 0.5rem;
  margin-top: 0.5rem;

    /* position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center; */
}

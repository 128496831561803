.evaluate-process-body {
  /* width: 100%; */
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 85vh;
}

.process-navbar {
  margin-top: 2rem;
  padding: 0 0.2rem;
}

.evaluation {
  margin: 0 0.2rem;
  padding: 0.5rem 1.5rem;
  background-color: rgba(233, 228, 228, 0.891);
}

.evaluation select {
  width: 100%;
}

.evaluation p {
  text-align: center;
  margin: 1.5rem 0;
  font-size: 1.4rem;
  font-weight: 500;
}

.evaluation input {
  width: 100%;
}

select {
  border: 0;
  height: 3rem;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .evaluate-process-body {
    font-size: 0.7rem !important;
    min-height: 48vh !important;
  }
}

.yes-no-body {
    display: flex;
    justify-content: space-between;
}

.function-table-yesno {
    margin-right: 20rem;
    margin-top: 1.5rem;
}

.yesno-button {
    display: flex;
    flex-direction: revert;
    position: absolute;
    top: 23rem;
    right: 2rem;
}

.header-yesno h2 {
    margin-top: -3.5rem;
}

.yesno-description {
    width: 17rem;
    border-radius: 10px;
    border:none;
    resize: none;
}

.icon-img-yesno {
    width: 2.2rem;
    height: 2.2rem;
    padding: 0.3rem;
}

.yesno-text input {
    width: 17rem !important;
}

.first-table-yesno {
    width: 42%;
    margin-top: 1rem;
}

.second-table-yesno {
    width: 39%;
    margin-left: 19%;
}

.create-sub-process-label-yesno {
    padding-right: 0.5rem;
}

.icon-img-yesno2 {
    width: 2.2rem;
    height: 2.2rem;
}

@media screen and (min-width:1000px) and (max-width:1300px) {
    .yesno-description {
        width: 14rem !important;
    }
    .first-table-yesno {
        width: 39%;
        margin-top: 1rem;
    }
    .second-table-yesno {
        width: 44%;
        margin-left: 19%;
    }
    .yesno-button {
        top: 22.5rem !important;
    }
    .yesno-text input {
        width: 14rem !important;
    }
}

@media screen and (min-width:1100px) and (max-width:1200px) {
    .yesno-button {
        top: 25.5rem !important;
    }
}

@media screen and (max-width:1000px) {
    .icon-img-yesno {
        width: 1.75rem;
        height: 1.75rem;
        padding: 0.3rem;
    }
    .icon-img-yesno2 {
        width: 1.75rem;
        height: 1.75rem;
    }
    .first-table-yesno {
        width: 55%;
        margin-top: 1rem;
    }
    .second-table-yesno {
        width: 45%;
        margin-left: 0%;
    }
    .yesno-description {
        width: 12rem !important;
    }
    .yesno-button {
        margin-top: 16px;
        margin-left: -111px;
    }
    .my-p {
        font-size: 1.25rem !important;
    }
    .yesno-button {
        top: 21.5rem;
    }
    .yesno-text input {
        width: 12rem !important;
    }
}
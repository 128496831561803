.col-2-2 {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  align-content: center;
}

.overview-sub-processes {
  /* Adjust the min-height to fix the footer's position  */
  min-height: 72vh;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.one-sub-process {
  width: 350px;
  /* height: 288px; */
  padding: 7px 2px;
  margin: 2rem 1rem;
  border-radius: 10px;
  text-align: center;
  background-color: gray;
}

.sub-process-trash-icon {
  width: 4rem;
  /* height: 2rem; */
}

.sub-process-trash-icon img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.create-sub-process-label {
  padding-right: 1rem;
}

.create-process-label img {
  width: 3rem;
  height: 3rem;
  border-radius: 10px;
}

.image-input2 {
  background-color: transparent;
  padding: 0;
}

.image-input2 input {
  display: none;
  background-color: transparent;
}

.back-btn1 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  position: fixed;
  right: 1rem;
  top: 12rem;
}

.save-btn-subprocess {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  position: absolute;
  right: 1rem;
  top: 15.7rem;
}

.no-subProcesses-indicator {
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 1260px) {
  .save-btn-process {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    position: absolute;
    right: 1rem;
    top: 8rem;
  }
  .save-btn-subprocess {
    top: 16rem;
  }
  .back-btn1 {
    top: 12rem;
  }
}

@media screen and (max-width: 1050px) {
  .save-btn-subprocess {
    top: 16rem;
  }
  .back-btn1 {
    top: 14rem;
  }
}

@media screen and (max-width: 974px) {
  .overview-sub-processes {
    max-width: 640px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1104px) and (max-width: 1400px) {
  .overview-sub-processes {
    max-width: 900px;
    justify-content: center;
  }
}

@media screen and (min-width: 974px) and (max-width: 1103px) {
  .overview-sub-processes {
    max-width: 640px;
    justify-content: center;
  }
}

@media screen and (min-width: 900px) and (max-width: 974px) {
  .overview-sub-processes {
    max-width: 640px;
    justify-content: center;
  }
}

@media screen and (min-width: 840px) and (max-width: 900px) {
  .overview-sub-processes {
    max-width: 640px;
    justify-content: center;
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 840px) {
  .overview-sub-processes {
    max-width: 640px;
    justify-content: center;
  }
}

.user-freetext-body {
  min-height: 63vh;
}

.freetext-textarea textarea {
  width: 95%;
  margin: 0 0.8rem;
  height: 45vh;
  display: flex;
  margin-bottom: 4rem;
}

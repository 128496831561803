.manage-locations-body {
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 77vh;
}

.title-btns {
  display: flex;
  justify-content: space-between;
  align-content: stretch;
}

@media screen and (max-width: 1050px) {
  .manage-locations-body {
    background-color: rgb(192, 192, 192);
    padding: 1rem;
    min-height: 48vh;
  }
}

@media screen and (max-width: 900px) {
  .manage-locations-body .title-btns {
    font-size: 0.8rem;
  }
}

    .Überschrift-margin {
        padding-left: 10rem;
    }

    .Überschrift-margin h2 {
        margin-top: -3.5rem;
    }

    .first-margin-scan {
        padding-left: 11.5rem;
    }

    .neues-item {
        width: 137px;
    }

    .scan-item {
        width: 137px;
    }

    .reset-btn-scan {
        display: flex;
        flex-direction: revert;
        position: absolute;
        top: 23.5rem;
        right: 2rem;
    }

    .evaluation-scan p {
        top: 24rem;
        font-size: 20px;
    }

    .scan-description {
        width: 23rem !important;
        margin-top: 2rem;
        border-radius:10px;
        border:none;
        resize: none;
    }

    .my-p {
        text-align: right !important;
        margin: 0 !important;
        font-size: 1.4rem;
        font-weight: 500;
        top: 19rem !important;
    }

    .function-table-scan select {
        width: 38rem;
    }

    .function-table-scan {
        margin-top: 1.5rem;
    }

    .main-table {
        display: flex;
    }

    .first-table {
        width: 35%;
    }

    .second-table {
        width: 55%;
        margin-left: 10%;
        margin-top: 1.5rem;
    }

    .reset-btn button {
        margin-top: 0.3rem;
    }

    .scan-field {
       width: 37.2rem;
    }

    .scan-title {
        width: 23rem;
    }

    .scan-select {
        width: 17rem;
    }

    .evaluation-scan {
        background-color: rgba(233, 228, 228, 0.8);
        padding: 0.5rem 1.5rem;
        /* display: flex; */
        margin: 0 0.2rem;
    }

    @media screen and (max-width:1335px) {
        .scan-title {
            width: 21rem;
        }
        .scan-description {
            width: 21rem !important;
        }
    }

    @media screen and (max-width:1265px) {
        .scan-title {
            width: 19rem;
        }
        .scan-description {
            width: 19rem !important;
        }
    }

    @media screen and (max-width:1240px) {
        .scan-title {
            width: 17rem;
        }
        .scan-description {
            width: 17rem !important;
        }
        .function-table-scan select {
            width: 35rem;
        }
        .scan-field {
            width: 34.5rem;
        }
    }

    @media screen and (min-width:1001px) and (max-width:1460px) {
        .function-table-scan select {
            width: 21rem !important;
        }
        .scan-field {
            width: 20.5rem !important;
        }
        .first-table {
            width: 29%;
        }
    }

    @media screen and (min-width:1000px) and (max-width:1150px) {
        .reset-btn-scan {
            top: 22rem !important;
        }
        .second-table {
            width: 58%;
        }
    }

    @media screen and (max-width:1000px) {
        .scan-field {
            width: 13.5rem;
        }
        .function-table-scan select {
            width: 14rem;
        }
        .Überschrift-margin {
            padding-left: 3rem;
        }
        .scan-description {
            width: 13rem !important;
        }
        .scan-title {
            width: 13rem;
        }
    }

    @media screen and (min-width:1000px) and (max-width:1400px) {
        .reset-btn-scan {
            top: 23rem;
        }
    }

    @media screen and (min-width:1101px) and (max-width:1119px) {
        .reset-btn-scan {
            top: 24rem !important;
        }
    }

    @media screen and (min-width:1001px) and (max-width:1050px) {
        .scan-title {
            width: 15.5rem;
        }
        .scan-description {
            width: 15.5rem !important;
        }
    }

    @media screen and (min-width:900px) and (max-width:1000px) {
        .reset-btn-scan {
            top: 23rem !important;
        }
    }

    @media screen and (min-width:870px) and (max-width:900px) {
        .reset-btn-scan {
            top: 21rem !important;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 870px) {
        .function-table {
            margin-top: 0rem;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1000px) {
        .first-table {
            width: 20%;
        }
        .second-table {
            width: 70%;
            margin-left: 10%;
            margin-top: 1.5rem;
        }
        .Überschrift-margin h2 {
            margin-top: -2.5rem;
            margin-left: 116px;
        }
        .reset-btn-scan {
            top: 21rem;
        }
    }
.overview-processes {
  min-height: 77vh;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}

.overview-page-new-process-button {
  display: flex;
  justify-content: flex-end;
}

.NeuerProzess-image {
  cursor: pointer;
  width: 20rem;
  height: 12rem;
}

.one-process {
  width: 350px;
  /* add height property to prevent the box flow */
  height: 40%;
  padding: 7px 2px;
  margin: 2rem 1rem;
  border-radius: 10px;
  text-align: center;
  background-color: gray;
}

.no-backgroundColor {
  background-color: transparent;
}

.overview-page-clickable-div {
  cursor: pointer;
}

.overview-page-process-title {
  font-size: 20px;
  font-weight: bold;
  background-color: #dfdfdf;
  border-radius: 5px;
  margin: 0 5px;
  padding: 3px 0;
  cursor: pointer;
}

.one-process-img {
  border-radius: 5px;
  margin: 0 5px;
  margin-top: 7px;
  width: 340px;
  height: 12rem;
  cursor: pointer;
}

.one-process-icons-container {
  width: 100%;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}

.one-process-icons-container img {
  cursor: pointer;
  background-color: #dfdfdf;
  margin: 0 5px;
  padding: 6px 58px;
  border-radius: 5px;
}

.icon-edit {
  width: 2.2rem;
  height: 2.2rem;
}

.Toastify__toast {
  position: absolute;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
}
/* 
.one-process-img{
  width: 15rem;
  height: 10rem;
} */

@media screen and (max-width: 974px) {
  .overview-processes {
    max-width: 640px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1104px) and (max-width: 1400px) {
  .overview-processes {
    max-width: 900px;
    justify-content: center;
  }
}

@media screen and (min-width: 974px) and (max-width: 1103px) {
  .overview-processes {
    max-width: 640px;
    justify-content: center;
  }
}

@media screen and (min-width: 900px) and (max-width: 974px) {
  .overview-processes {
    max-width: 640px;
    justify-content: center;
  }
}

@media screen and (min-width: 840px) and (max-width: 900px) {
  .overview-processes {
    max-width: 640px;
    justify-content: center;
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 840px) {
  .overview-processes {
    max-width: 640px;
    justify-content: center;
  }
}

.subProcess-table {
  margin-top: 1rem;
}

.manage-sub-process-dropdown-container {
  display: flex;
  align-items: center;
  /* add margin to keep space between the title and dropdown */
  margin-top: 1rem;
}

select {
  border: 0;
  border-radius: 10px;
  /* margin-left: 1rem; */
  height: 3rem;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .edit-processes .col-1,
  .create-process-subProcess-forms {
    font-size: 0.7rem !important;
  }
  .save-btn-subprocess {
    margin: 0rem !important;
    top: 14.5rem !important;
    position: absolute !important;
  }
}

.large-textbox-instruction input {
    width: 47rem !important;
}

.instruction-description {
    width: 47rem !important;
    border-radius:10px;
    border:none;
    resize: none;
}

.header-instruction h2 {
    margin-top: -3.5rem;
}

.evaluation-instruction {
    background-color: rgba(233, 228, 228, 0.8);
    padding: 0.5rem 1.5rem;
    /* display: flex; */
    margin: 0 0.2rem;
}

.reset-btn-instruction {
    display: flex;
    flex-direction: revert;
    position: absolute;
    top: 22.5rem;
    right: 2rem;
}

@media screen and (min-width:1100px) and (max-width:1200px) {
    .reset-btn-instruction {
        top: 23.5rem !important;
    }
    .function-table-instruction {
        margin-top: 2rem;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    .reset-btn-instruction {
        top: 22.5rem;
    }
    .large-textbox-instruction input {
        width: 35rem !important;
    }
    .instruction-description {
        width: 35rem !important;
    }
    .function-table-instruction {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 900px) {
    .reset-btn-instruction {
        top: 21rem !important;
    }
    .large-textbox-instruction input {
        width: 35rem !important;
    }
    .instruction-description {
        width: 35rem !important;
    }
    .function-table-instruction {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 770px) {
    .reset-btn-instruction {
        top: 22rem !important;
    }
}
.create-edit-ip-address {
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 77vh;
}

.create-edit-ip-address-body{
      display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
}

.save-cancel-btns-in-system-settings{
    margin: 2rem;
}
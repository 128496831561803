.login {
  padding: 0 1rem;
  margin: 7% 25% 10%;
  height: 46.5vh;
}

.login-hr {
  margin: 1.3rem 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-label {
  width: 8rem;
}

.login-form input {
  width: 100%;
}

.login-btn {
  text-align: center;
  margin: 0.5rem 0;
}

.login-error-message {
  color: red;
}

.home {
  padding: 0 1rem;
  text-align: center;
}
sup {
  color: yellow;
  font-size: xx-large;
  font-weight: bolder;
}

.user-instruction {
  width: 99%;
  min-height: 80vh;
}

.user-instruction-body {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

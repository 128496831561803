.create-processes-body {
    background-color: rgb(192, 192, 192);
    padding: 1rem;
    min-height: 77vh;
}

.processes-table {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:900px) {
    .create-processes-body .title-btns {
        font-size: 0.7rem !important;
    }
}
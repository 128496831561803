.licenses {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.my-td-label-licenses {
  border-width: 3px;
  border-color: #aaaaaa;
  border-style: solid;
  padding: 0.7rem;
  padding-right: 4rem;
}
@media screen and (max-width:1030px) {
  .my-td-label-licenses {
    font-size: 14px;
}
}
@media screen and (max-width: 1350px) {
  .licenses {
    display: inherit;
    overflow-x: scroll;
    overflow: auto;
  }
}

@media screen and (max-width: 900px) {
  .my-td-label-licenses{
    padding:10px 10px;
  }
}
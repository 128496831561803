.manage-users-body {
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 77vh;
}

.topBtn {
  display: flex;
  justify-content: space-between;
}

.users-table {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .manage-users-body .title-btns {
    font-size: 0.8rem;
  }
}

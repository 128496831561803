.sensrecAdmin-navbar {
  padding: 0 0.2rem;
}

.my-sensrecAdmin-ul {
  display: flex;
  padding: 0;
}

.my-sensrecAdmin-li {
  width: 18%;
  text-align: center;
  margin: 0 0.5rem;
  margin-left: 0;
  padding: 0.5rem;
  background-color: rgb(192, 192, 192);
  list-style-type: none;
  border-radius: 0.8rem;
  border-end-start-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.my-sensrecAdmin-li-active {
  width: 18%;
  text-align: center;
  margin: 0 0.5rem;
  margin-left: 0;
  padding: 0.5rem;
  background-color: rgb(192, 192, 192);
  list-style-type: none;
  border-radius: 0.8rem;
  border-end-start-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0px;
}

a {
  color: rgb(66, 63, 63);
  text-decoration: none;
}

.my-sensrecAdmin-is-active {
  font-size: 1.8rem;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .my-sensrecAdmin-li {
    font-size: 1.4rem;
  }
  .my-sensrecAdmin-is-active {
    font-size: 1.6rem;
  }
}
.user-scan-body {
  min-height: 63vh;
}

.barcode-scanner-body {
  display: flex;
}

.scanner-input {
  min-height: 45vh;
  flex: 2;
}

.scanner-output {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.scanner-output div {
  margin-bottom: 1rem;
  padding: 0.4rem;
}

.scanner-output input {
  margin-bottom: 1rem;
}

.back-next-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 0 1.8rem;
}

.back-next-buttons button {
  width: 10rem;
}

.sub-process-function-name {
  display: flex;
  background-color: rgb(174, 174, 174);
  align-items: center;
}

.my-App {
  font-family: sans-serif;
  text-align: center;
  margin: 2rem;
  /* height: 99vh;
  width: 99vw; */
  /* margin: 0; */
  padding: 0;
  overflow: hidden;
}

.container {
  position: relative;
}

.container,
#interactive.viewport {
  width: 400px;
  height: 230px;
}

#interactive.viewport canvas,
video {
  width: 400px;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 400px;
  height: 250px;
}

.scan-video {
  margin-left: 3rem;
  width: 80%;
  max-width: 90%;
  height: auto;
}

.users {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 2rem; */
}

.h2-action {
  margin-left: 2rem;
}

@media screen and (max-width: 1350px) {
  .users {
    display: inherit;
    overflow-x: scroll;
    overflow: auto;
  }
  .my-td-label-user{
    font-size: 15px;
  }
}

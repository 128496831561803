    .process-name-input-function {
        width: 20rem;
    }

    .process-name-input-function input {
        width: 100%;
    }

    .evaluation-count {
        background-color: rgba(233, 228, 228, 0.8);
        padding: 0.5rem 1.5rem;
        /* display: flex; */
        margin: 0 0.2rem;
    }

    .evaluation-count p {
        top: 24rem;
        font-size: 20px;
    }

    .count-body {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
    }

    .count-second-row {
        margin-left: 2rem;
    }

    .first-table-count {
        width: 50%;
    }

    .second-table-count {
        width: 40%;
        margin-left: 10%;
        margin-top: 38px;
    }

    .count-second-table-margin {
        width: 20rem;
    }

    .count-form {
        display: flex;
    }

    .function-table select {
        width: 17rem;
    }

    .reset-btn-count {
        display: flex;
        flex-direction: revert;
        position: absolute;
        top: 23rem;
        right: 2rem;
    }

    .icon-img {
        width: 2.2rem;
        height: 2.2rem;
        padding: 0.5rem !important;
        /* opacity: 0.5; */
    }

    .icon-img2 {
        width: 2.2rem;
        height: 2.2rem;
    }

    .header-count h2 {
        margin-top: -3.5rem;
    }

    .count-last-field {
        margin-right: 2rem;
        width: 17rem !important;
        border-radius:10px;
        resize: none;
    }

    .evaluation-count input {
        width: 17rem;
    }
/* add the style for the count-unit-dropdown className */
    .count-unit-dropdown {
      width: 15rem;
      margin-left: 10rem;
    }

    @media screen and (min-width:1070px) and (max-width:1250px) {
        .function-table select {
            width: 26rem;
        }
        .evaluation input {
            width: 25rem !important;
        }
        .count-last-field {
            margin-right: 2rem;
            width: 25rem !important;
        }
        .count-title {
            width: 25rem !important;
        }
        .count-unit-dropdown {
            min-width: 15rem;
        }
}

    @media screen and (min-width:1200px) and (max-width:1400px) {
        .second-table-count {
            width: 40%;
            margin-top: 1.7rem;
            margin-left: 10%;
        }
        .count-unit-dropdown {
            min-width: 15rem;
        }
    }

    @media screen and (max-width:1250px) {
        .first-table-count {
            width: 50%;
        }
        .count-body {
            flex-direction: column;
        }
        .second-table-count {
            width: 50%;
            margin-top: 85px;
            margin-left: 0px;
        }
        .create-buttons {
            margin-top: -15rem;
            position: absolute;
            /* top: 740px; */
        }
        .count-unit-dropdown {
           min-width: 15rem;
        }
    }

    @media screen and (min-width:1000px) and (max-width:1070px) {
        .second-table-count {
            width: 23%;
            margin-top: 5.7rem !important;
        }
      .create-buttons {
        margin-top: -15rem;
        position: absolute;
        /* top: 692px !important; */
      }
      .count-unit-dropdown {
        min-width: 15rem;
      }
    }

    @media screen and (min-width:768px) and (max-width:900px) {
        .create-buttons {
          margin-top: -15rem;
          position: absolute;
          /* top: 692px !important; */
        }
        .reset-btn-count {
            top: 21rem;
            right: 2rem;
        }
        .count-title {
            width: 17rem;
        }
      .count-unit-dropdown {
        min-width: 15rem;
      }
    }
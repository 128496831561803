.system-setting-body {
  /* width: 100%; */
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 85vh;
}

.maximum-number-of-licenses{
  margin-left:1rem;
}

@media screen and (max-width: 900px) {
  .system-setting-body {
    font-size: 0.7rem !important;
  }
}

    .list-function-body {
        background-color: rgba(233, 228, 228, 0.8);
        padding: 0.5rem 1.5rem;
        /* display: flex; */
        margin: 0 0.2rem;
    }

    .header-list h2 {
        margin-top: -3.5rem;
    }

    .list-text {
        width: 17rem;
    }

    .first-table-list {
        width: 42%;
        margin-top: 1rem;
    }

    .second-table-list {
        width: 39%;
        margin-left: 19%;
        margin-top: 1.5rem;
    }

    /* .function-table-list {
        margin-top: 1.5rem;
        margin-left: 0rem;
    } */

    .icon-img-list {
        width: 2.2rem;
        height: 2.2rem;
        padding: 0.3rem;
    }

    .icon-img-list2 {
        width: 2.2rem;
        height: 2.2rem;
    }

    .list-description {
        width: 17rem !important;
        border-radius: 10px;
        border: none;
        resize: none;
    }

    .list-body {
        display: flex;
    }

    .first-table-list input {
        width: 35rem;
    }

    .instructionItem-name-input input {
        width: 25rem;
    }

    .create-sub-process-label-list {
        padding-right: 0.5rem;
        /* padding-top: 2rem; */
    }

    .create-process-label img {
        width: 3rem;
        height: 3rem;
    }

    .image-input2 {
        background-color: transparent;
        padding: 0;
    }

    .my-p-list {
        text-align: right !important;
        font-size: 1.4rem;
        font-weight: 500;
        top: 20rem !important;
        margin: 0rem !important;
    }

    .image-input2 input {
        display: none;
        background-color: transparent;
    }

    .reset-btn-list {
        display: flex;
        flex-direction: revert;
        position: absolute;
        top: 23rem;
        right: 2rem;
    }

    .back-btn1 {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
        position: fixed;
        /* margin-top: -5rem; */
        right: 1rem;
        top: 12rem;
    }

    .icon-padding {
        padding-left: 1rem;
    }

    @media screen and (max-width: 1260px) {
        .save-btn-process {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1rem;
            position: fixed;
            right: 1rem;
            top: 8rem;
        }
        .save-btn-subprocess {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1rem;
            position: fixed;
            right: 1rem;
            top: 16rem;
        }
        .back-btn1 {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1rem;
            position: fixed;
            /* margin-top: -5rem; */
            right: 1rem;
            top: 12rem;
        }
    }

    @media screen and (max-width: 1050px) {
        .save-btn-process {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1rem;
            position: fixed;
            right: 1rem;
            top: 10rem;
        }
        .save-btn-subprocess {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1rem;
            position: fixed;
            right: 1rem;
            top: 18rem;
        }
        .back-btn1 {
            top: 14rem;
        }
        .list-function-body input {
            width: 12rem !important;
        }
        .function-table-list {
            margin-top: 1.5rem;
            margin-left: 12rem;
        }
        .my-p-list {
            text-align: right !important;
            font-size: 1.25rem;
            font-weight: 500;
            top: 20rem !important;
            margin: 0rem !important;
        }
        .icon-img-list {
            width: 1.75rem;
            height: 1.75rem;
            padding: 0.3rem;
        }
        .icon-img-list2 {
            width: 1.75rem;
            height: 1.75rem;
        }
        .first-table-list {
            width: 25%;
            margin-top: 1rem;
        }
        .second-table-list {
            width: 75%;
            /* margin-left: 0%; */
            margin-left: 40%;
            margin-top: 1.5rem;
        }
        .list-description {
            width: 12rem !important;
        }
        .reset-btn-list {
            top: 21rem;
        }
    }

    @media screen and (min-width:1000px) and (max-width:1150px) {
        .reset-btn-list {
            top: 22rem;
        }
        .list-function-body input {
            width: 14rem !important;
        }
        .list-description {
            width: 14rem !important;
        }
    }

    @media screen and (min-width:900px) and (max-width:1000px) {
        .reset-btn-list {
            top: 22.5rem;
            padding-top: 15px;
        }
    }

    @media screen and (min-width:768px) and (max-width:1000px) {
        .reset-btn-list {
            top: 21.3rem;
        }
    }
.manage-licenses-body {
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 77vh;
}

.topBtn {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.topBtn button {
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  .manage-licenses-body .title-btns {
    font-size: 0.8rem;
  }
}

.my-ul {
    display: flex;
    padding: 0;
    margin: 0;
}

.my-function-li {
    width: 8.9rem;
    text-align: center;
    margin: 0 0.5rem;
    margin-left: 0;
    padding: 0.5rem;
    background-color: rgba(233, 228, 228, 0.8);
    list-style-type: none;
    border-radius: 0.8rem;
    border-end-start-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.my-function-li-active {
    width: 8.9rem;
    text-align: center;
    margin: 0 0.5rem;
    margin-left: 0;
    padding: 0.5rem;
    background-color: rgba(233, 228, 228, 0.8);
    list-style-type: none;
    border-radius: 0.8rem;
    border-end-start-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0px;
}

a {
    color: rgb(66, 63, 63);
    text-decoration: none;
}

.my-is-active {
    font-size: 1.4rem;
    font-weight: 700;
}

@media screen and (max-width: 1300px) {
    .my-li {
        width: 18%;
        text-align: center;
        margin: 0 0.3rem;
        margin-left: 0;
        padding: 0.5rem;
        background-color: rgb(192, 192, 192);
        list-style-type: none;
        border-radius: 0.8rem;
        border-end-start-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 0.7rem;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .my-li-active {
        width: 18%;
        text-align: center;
        margin: 0 0.3rem;
        margin-left: 0;
        padding: 0.5rem;
        background-color: rgb(192, 192, 192);
        list-style-type: none;
        border-radius: 0.8rem;
        border-end-start-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0px;
    }
    a {
        color: rgb(66, 63, 63);
        text-decoration: none;
    }
    .my-is-active {
        font-size: 1.2rem;
        font-weight: 700;
    }
}

@media screen and (max-width :1100px) {
    .my-function-li {
        width: 8.9rem;
        text-align: center;
        margin: 0 0.5rem;
        margin-left: 0;
        padding: 0.5rem;
        background-color: rgba(233, 228, 228, 0.8);
        list-style-type: none;
        border-radius: 0.8rem;
        border-end-start-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 0.7rem;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .my-function-li-active {
        width: 8.9rem;
        text-align: center;
        margin: 0 0.5rem;
        margin-left: 0;
        padding: 0.5rem;
        background-color: rgba(233, 228, 228, 0.8);
        list-style-type: none;
        border-radius: 0.8rem;
        border-end-start-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0px;
    }
}
.user-overview-processes {
  display: flex;
  flex-wrap: wrap;
  min-height: 82vh;
  margin:1rem;
}

.user-one-process {
  width: 280px;
  height: 225px;
  padding: 7px 2px;
  margin: 0.5rem 0.8rem;
  border-radius: 10px;
  text-align: center;
  background-color: gray;
}

.user-one-process h4 {
  text-align: center;
  margin: 0 2rem;
  width: 96.5%;
  font-weight: bold;
  background-color: #dfdfdf;
  border-radius: 5px;
  margin: 0 5px;
  padding: 3px 0;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-one-process-img {
  border-radius: 5px;
  margin: 0 5px;
  margin-top: 7px;
  width: 290px;
  width: 96%;
  height: 167px;
}

.NeuerProzess-image {
  width: 14rem;
  height: 9rem;
}

.Toastify__toast {
  position: absolute;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
}



/* Remove the old media queries and new ones for the responsive design */

@media screen and (max-width: 974px) {
  .user-overview-processes {
    max-width: 640px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1104px) and (max-width: 1400px) {
  .user-overview-processes {
    max-width: 900px;
    justify-content: center;
  }
}

@media screen and (min-width: 974px) and (max-width: 1103px) {
  .user-overview-processes {
    max-width: 640px;
    justify-content: center;
  }
}

@media screen and (min-width: 900px) and (max-width: 974px) {
  .user-overview-processes {
    max-width: 640px;
    justify-content: center;
  }
}

@media screen and (min-width: 840px) and (max-width: 900px) {
  .user-overview-processes {
    max-width: 640px;
    justify-content: center;
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 840px) {
  .user-overview-processes {
    max-width: 640px;
    justify-content: center;
  }
}

.user-list-body {
  min-height: 59vh;
}

.list-items {
  margin: 0.5rem;
  padding: 0.5rem;
  width: 30rem;
  background-color: rgb(174, 174, 174);
  margin-bottom: 3rem;
}

.one-list-item-default {
  background-color: white;
  border-style: solid;
  border-color: black;
  /* width: 50%; */
  height: 2rem;
  border-width: 1px;
  margin: 0.8rem 0;
  padding: 0.2rem 0;
}

.one-list-item-done {
  color: white;
  background-color: green;
  border-style: solid;
  border-color: black;
  /* width: 50%; */
  height: 2rem;
  border-width: 1px;
  margin: 0.8rem 0;
  padding: 0.2rem 0;
}

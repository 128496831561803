.my-td-label-location {
  border-width: 3px;
  border-color: #aaaaaa;
  border-style: solid;
  padding: 0.7rem;
  padding-right: 3rem;
}

@media screen and (max-width: 900px) {
  .my-td-label-location {
    border-width: 3px;
    border-color: #aaaaaa;
    border-style: solid;
    padding: 0.7rem;
    padding-right: 2rem;
  }
}

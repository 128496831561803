input {
  border: 0;
  height: 3rem;
  border-radius: 10px;
}
input:focus {
  /* remove the border property  */
  /* border: 2px solid yellow !important; */
  height: 3rem;
  outline: none !important;
}

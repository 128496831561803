.user-photo {
  min-height: 64vh;
}

.camera {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.photo-video {
  position: relative;
  margin-left: 12rem;
  width: 30rem;
  /* max-width: 70%; */
  height: 18rem;
  /* position: relative; */
  border: solid;
  border-width: 0.2rem;
  border-radius: 1rem;
  border-color: yellow;
}

.result {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.result canvas {
  margin-left: 15rem;
  width: 30rem;
  /* max-width: 70%; */
  height: 18rem;
  /* position: relative; */
  border: solid;
  border-width: 0.2rem;
  border-radius: 1rem;
  border-color: yellow;
}

.none {
  display: none;
}

.none canvas {
  display: none;
}

.none button {
  display: none;
}

/* .hasPhoto {
  position: relative;
  top: 5rem;
} */

/* .snap-button {
  width: 5rem;
  height: 3rem;
  text-align: center;
} */

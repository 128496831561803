.license {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.my-td-label-license {
  border-width: 3px;
  border-color: #aaaaaa;
  border-style: solid;
  padding: 0.7rem;
  padding-right: 4rem;
}

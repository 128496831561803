.create-processes-forms {
  display: flex;
  margin: 3rem;
  justify-content: center;
}

.create-process-label {
  padding-right: 0.5rem;
}

.create-process-label img {
  margin-left: 1.5rem;
}

.process-name-input {
  width: 40rem;
}

.process-name-input input {
  width: 100%;
}

.image-input {
  background-color: transparent;
  padding: 0;
}

.image-input input {
  display: none;
  background-color: transparent;
}

.save-btn-process {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  position: fixed;
  right: 1rem;
  top: 8.8rem;
}
.process-save-btn {
  padding-top: 1rem;
  text-align: center;
}

.save-btn-subprocess {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  position: fixed;
  right: 1rem;
  top: 16rem;
}

.process-trash-icon img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 1260px) {
  .save-btn-process {
    right: 1rem;
    top: 8rem;
  }
  .back-btn1 {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    position: fixed;
    right: 1rem;
    top: 13rem;
  }
}

@media screen and (max-width: 1050px) {
  .save-btn-process {
    right: 1rem;
    top: 10rem;
  }

  .save-btn-subprocess {
    right: 1rem;
    top: 18rem;
  }

  .back-btn1 {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    position: fixed;
    right: 1rem;
    top: 20rem;
  }
}

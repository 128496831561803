.create-user-body {
  background-color: rgb(192, 192, 192);
  padding: 1rem;
  min-height: 77vh;
}

.create-user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-user-form label {
  margin-left: 2.5rem;
}

.new-user-error-message {
  color: red;
}

.my-td-input {
  /* border-width: 3px;
  border-color: #aaaaaa;
  border-style: solid; */
  width: 15rem;
}

/* it was 98% */
.my-td-input input {
  width: 96%;
}

.firstname,
.lastname,
.email,
.password,
.ip {
  margin-bottom: 0.5rem;
}

.create-user-p {
  display: flex;
}

select {
  border: 0;
  height: 3rem;
  width: 100%;
}
